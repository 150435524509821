@import '../base.scss';

.title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
    min-width: 320px;

    li {
        margin: 5px 0;
    }

    img {
        width: 20em;
        border-radius: 50%;
    }

    h1, h2, h3,
    h4, h5, h6 {
        margin: 0;
    }

    h1 {
        font-size: 2.75em;
    }

    h2 {
        font-size: 1.5em;
        font-weight: 400;
    }

    span {
        font-family: monospace;
        font-size: 1.5em;
    }

    a {
        color: #3693ff;
        text-decoration: none;
    }
}

.icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    color: #61dafb;
    margin: 10px;

    li {
        margin: 0 5px;
    }

    li:hover {
        transform: scale(1.2);
    }

    a { 
        color: inherit;
        text-decoration: none;
    }
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    .interests {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 320px;

        font-size: 1.2em;

        div {
            font-weight: bold;
        }
    }
}

@font-face {
    font-family: 'Academicons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src:url('../../assets/icons/academicons/fonts/academicons.eot');
    src:url('../../assets/icons/academicons/fonts/academicons.eot') format('embedded-opentype'),
        url('../../assets/icons/academicons/fonts/academicons.ttf') format('truetype'),
        url('../../assets/icons/academicons/fonts/academicons.woff') format('woff'),
        url('../../assets/icons/academicons/fonts/academicons.svg') format('svg');
}

.ai {
    font-family: 'Academicons';
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;  
}

.ai-cv:before {
    content: "\e9a5";
}

.ai-2x {
    font-size: 2.7em;
}
  